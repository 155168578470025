var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: { height: "100%, width: 100%" },
      attrs: { id: _vm.componentId },
    },
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "lg",
            title: _vm.labelTitle,
            "footer-class": "footerClass",
            "no-close-on-backdrop": "",
            "content-class": "shadow",
            "modal-class": [_vm.componentId],
            scrollable: "",
          },
          on: { ok: _vm.modalOk, hidden: _vm.modalCancel },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  _c("h5", { staticClass: "custom-modal-title" }, [
                    _vm._v(" " + _vm._s(_vm.labelTitle) + " "),
                  ]),
                  _vm.exists
                    ? [
                        _c(
                          "b-button",
                          {
                            staticClass: "history-button",
                            attrs: { variant: "secondary", size: "sm" },
                            on: {
                              click: function ($event) {
                                _vm.state.historyShow = true
                              },
                            },
                          },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: ["far", "clock-rotate-left"] },
                            }),
                            _vm._v(
                              " " + _vm._s(_vm.$t("button.history")) + " "
                            ),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _c(
                    "button",
                    {
                      staticClass: "close custom-modal-close",
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v("×")]
                  ),
                ]
              },
            },
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  _vm.exists && _vm.canView("STAFF")
                    ? _c(
                        "b-button",
                        {
                          staticStyle: { "margin-right": "auto" },
                          attrs: { size: "sm", variant: "secondary" },
                          on: { click: _vm.viewStaff },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("button.view_staff")) + " "
                          ),
                        ]
                      )
                    : _vm._e(),
                  !_vm.isAccessDenied &&
                  ((_vm.exists && _vm.canEdit()) ||
                    (!_vm.exists && _vm.canAdd()))
                    ? [
                        _vm.state.isSubmitting
                          ? _c(
                              "b-button",
                              {
                                attrs: {
                                  size: "sm",
                                  variant: "success",
                                  disabled: "",
                                },
                              },
                              [
                                _c("b-spinner", {
                                  attrs: { small: "", type: "grow" },
                                }),
                                _vm._v(_vm._s(_vm.$t("button.saving")) + " "),
                              ],
                              1
                            )
                          : _c(
                              "b-button",
                              {
                                attrs: { size: "sm", variant: "success" },
                                on: { click: _vm.modalOk },
                              },
                              [_vm._v(_vm._s(_vm.$t("button.ok")))]
                            ),
                      ]
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.state.modalShow,
            callback: function ($$v) {
              _vm.$set(_vm.state, "modalShow", $$v)
            },
            expression: "state.modalShow",
          },
        },
        [
          _vm.isAccessDenied
            ? [
                _c("div", { staticClass: "modal-message-overlay" }, [
                  _c("span", { staticClass: "grid-overlay" }, [
                    _vm._v(
                      _vm._s(
                        _vm.restrictedRequiredField != null
                          ? _vm.$t(
                              "entity_selector.error.insufficient_permission_to_add_entity_with_reason",
                              [
                                _vm.$t("resource.title").toLowerCase(),
                                _vm.restrictedRequiredField,
                              ]
                            )
                          : _vm.$t(
                              "entity_selector.error.insufficient_permission_to_add_entity",
                              [_vm.$t("resource.title").toLowerCase()]
                            )
                      )
                    ),
                  ]),
                ]),
              ]
            : [
                _c(
                  "b-alert",
                  {
                    attrs: {
                      variant: "danger",
                      dismissible: "",
                      show: _vm.showError,
                    },
                    on: { dismissed: _vm.dismissAlert },
                  },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: ["fas", "triangle-exclamation"] },
                    }),
                    _vm._v("  " + _vm._s(_vm.alertMsg) + " "),
                    _c(
                      "ul",
                      {
                        staticClass: "mb-0",
                        attrs: { show: _vm.showErrorDetail },
                      },
                      [
                        _vm._l(_vm.alertMsgDetails, function (item, index) {
                          return [
                            _c("li", { key: index }, [_vm._v(_vm._s(item))]),
                          ]
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "container pl-0" },
                  [
                    _c(
                      "b-row",
                      [
                        _vm.isNameVisible
                          ? _c(
                              "b-col",
                              {
                                staticClass: "pr-0",
                                attrs: { cols: "12", md: "8" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.$t("resource.field.name"),
                                      "label-for": "name",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          directives: [
                                            {
                                              name: "validate",
                                              rawName: "v-validate",
                                              value: { required: true },
                                              expression: "{ required: true }",
                                            },
                                          ],
                                          attrs: {
                                            id: "name",
                                            type: "text",
                                            "data-vv-as": _vm.$t(
                                              "resource.field.name"
                                            ),
                                            "data-vv-name": "resource.name",
                                            "data-vv-delay": "500",
                                            readonly: _vm.isNameReadOnly,
                                            trim: "",
                                            state:
                                              _vm.fieldValidateUtil.stateValidate(
                                                _vm.isReadOnly,
                                                _vm.veeFields,
                                                _vm.errors,
                                                "resource.name"
                                              ),
                                          },
                                          model: {
                                            value: _vm.resource.name,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.resource,
                                                "name",
                                                $$v
                                              )
                                            },
                                            expression: "resource.name",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-form-invalid-feedback",
                                      {
                                        staticClass:
                                          "alert-danger form-field-alert",
                                        class: { "d-block": _vm.showNameError },
                                      },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: {
                                            icon: ["far", "circle-exclamation"],
                                          },
                                        }),
                                        _vm._v(
                                          "  " +
                                            _vm._s(
                                              _vm.errors.first("resource.name")
                                            ) +
                                            " "
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["name"] != null
                          ? _vm._l(
                              _vm.customFieldMap["name"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "name" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.resource[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.resource,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "resource[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isIdentifierVisible
                          ? _c(
                              "b-col",
                              {
                                staticClass: "pr-0",
                                attrs: { cols: "12", md: "4" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.$t("field.identifier"),
                                      "label-for": "identifier",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            id: "identifier",
                                            type: "text",
                                            "data-vv-as":
                                              _vm.$t("field.identifier"),
                                            "data-vv-name":
                                              "resource.identifier",
                                            maxlength: _vm.maxIdentifierLength,
                                            readonly: _vm.isIdentifierReadOnly,
                                            trim: "",
                                          },
                                          model: {
                                            value: _vm.resource.identifier,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.resource,
                                                "identifier",
                                                $$v
                                              )
                                            },
                                            expression: "resource.identifier",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["default"] != null
                          ? _vm._l(
                              _vm.customFieldMap["default"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.resource[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.resource,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "resource[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.customFieldMap["identifier"] != null
                          ? _vm._l(
                              _vm.customFieldMap["identifier"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "identifier" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.resource[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.resource,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "resource[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isDescriptionVisible
                          ? _c(
                              "b-col",
                              { staticClass: "pr-0", attrs: { cols: "12" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.$t(
                                        "resource.field.description"
                                      ),
                                      "label-for": "description",
                                    },
                                  },
                                  [
                                    _c("b-form-textarea", {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: {
                                            max: _vm.maxDescriptionLength,
                                          },
                                          expression:
                                            "{ max: maxDescriptionLength }",
                                        },
                                      ],
                                      attrs: {
                                        id: "description",
                                        placeholder: _vm.isDescriptionReadOnly
                                          ? ""
                                          : _vm.$t(
                                              "resource.placeholder.description"
                                            ),
                                        "data-vv-as": _vm.$t(
                                          "resource.field.description"
                                        ),
                                        "data-vv-name": "resource.description",
                                        "data-vv-delay": "500",
                                        "max-rows": 6,
                                        readonly: _vm.isDescriptionReadOnly,
                                        trim: "",
                                        rows: 3,
                                      },
                                      model: {
                                        value: _vm.resource.description,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.resource,
                                            "description",
                                            $$v
                                          )
                                        },
                                        expression: "resource.description",
                                      },
                                    }),
                                    _c(
                                      "b-form-invalid-feedback",
                                      {
                                        staticClass:
                                          "alert-danger form-field-alert",
                                        class: {
                                          "d-block": _vm.showDescriptionError,
                                        },
                                      },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: {
                                            icon: ["far", "circle-exclamation"],
                                          },
                                        }),
                                        _vm._v(
                                          "  " +
                                            _vm._s(
                                              _vm.errors.first(
                                                "resource.description"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["description"] != null
                          ? _vm._l(
                              _vm.customFieldMap["description"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "description" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.resource[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.resource,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "resource[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isPayAmountVisible
                          ? [
                              _c(
                                "b-col",
                                {
                                  staticClass: "pr-0",
                                  attrs: { cols: "12", md: "8" },
                                },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: _vm.$t("resource.field.cost"),
                                        "label-for": "cost",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-input-group",
                                        [
                                          _c("b-form-input", {
                                            directives: [
                                              {
                                                name: "validate",
                                                rawName: "v-validate",
                                                value: {
                                                  required: true,
                                                  min_value: 0.0,
                                                },
                                                expression:
                                                  "{ required: true, min_value: 0.00 }",
                                              },
                                            ],
                                            attrs: {
                                              id: "cost",
                                              type: "number",
                                              min: 0.0,
                                              step: 1,
                                              "lazy-formatter": "",
                                              formatter: _vm.floatFormatter,
                                              "data-vv-as": _vm.$t(
                                                "resource.field.cost"
                                              ),
                                              "data-vv-name":
                                                "resource.payAmount",
                                              "data-vv-delay": "500",
                                              readonly: _vm.isPayAmountReadOnly,
                                              trim: "",
                                            },
                                            model: {
                                              value: _vm.resource.payAmount,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.resource,
                                                  "payAmount",
                                                  $$v
                                                )
                                              },
                                              expression: "resource.payAmount",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-form-invalid-feedback",
                                        {
                                          staticClass:
                                            "alert-danger form-field-alert",
                                          class: {
                                            "d-block": _vm.showCostError,
                                          },
                                        },
                                        [
                                          _c("font-awesome-icon", {
                                            attrs: {
                                              icon: [
                                                "far",
                                                "circle-exclamation",
                                              ],
                                            },
                                          }),
                                          _vm._v(
                                            "  " +
                                              _vm._s(
                                                _vm.errors.first(
                                                  "resource.payAmount"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                        _vm.customFieldMap["payAmount"] != null
                          ? _vm._l(
                              _vm.customFieldMap["payAmount"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "payAmount" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.resource[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.resource,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "resource[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isColorVisible
                          ? _c(
                              "b-col",
                              {
                                staticClass: "pr-0",
                                attrs: { cols: "12", md: "4" },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "color-container" },
                                  [
                                    _c("Color", {
                                      attrs: {
                                        disabled: _vm.isColorReadOnly,
                                        update: _vm.updatedColor,
                                      },
                                      model: {
                                        value: _vm.resource.color,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.resource, "color", $$v)
                                        },
                                        expression: "resource.color",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.customFieldMap["color"] != null
                          ? _vm._l(
                              _vm.customFieldMap["color"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "color" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.resource[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.resource,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "resource[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isPayCurrencyVisible
                          ? _c(
                              "b-col",
                              {
                                staticClass: "pr-0",
                                attrs: { cols: "12", md: "8" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.$t("resource.field.currency"),
                                      "label-for": "currency",
                                    },
                                  },
                                  [
                                    _c("b-input-group", [
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.resource.payCurrency,
                                              expression:
                                                "resource.payCurrency",
                                            },
                                          ],
                                          staticClass: "custom-select",
                                          attrs: {
                                            id: "project-priority",
                                            disabled: _vm.isPayCurrencyReadOnly,
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$selectedVal =
                                                Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function (o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function (o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                              _vm.$set(
                                                _vm.resource,
                                                "payCurrency",
                                                $event.target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._l(
                                            _vm.currencyOpts,
                                            function (opt, index) {
                                              return [
                                                _c(
                                                  "option",
                                                  {
                                                    key: index,
                                                    style: {
                                                      display:
                                                        opt.num < 0
                                                          ? "none"
                                                          : "block",
                                                    },
                                                    domProps: {
                                                      value: opt.value,
                                                    },
                                                  },
                                                  [_vm._v(_vm._s(opt.text))]
                                                ),
                                              ]
                                            }
                                          ),
                                        ],
                                        2
                                      ),
                                    ]),
                                    _c(
                                      "b-form-invalid-feedback",
                                      {
                                        staticClass:
                                          "alert-danger form-field-alert",
                                        class: {
                                          "d-block": _vm.showCurrencyError,
                                        },
                                      },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: {
                                            icon: ["far", "circle-exclamation"],
                                          },
                                        }),
                                        _vm._v(
                                          "  " +
                                            _vm._s(
                                              _vm.errors.first(
                                                "resource.payCurrency"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["payCurrency"] != null
                          ? _vm._l(
                              _vm.customFieldMap["payCurrency"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "payCurrency" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.resource[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.resource,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "resource[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isResourceQuotaVisible
                          ? _c(
                              "b-col",
                              { staticClass: "pr-0", attrs: { cols: "4" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.$t(
                                        "resource.field.resourceQuota"
                                      ),
                                      "label-for": "resourceQuota",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        !_vm.isResourceQuotaReadOnly
                                          ? _c(
                                              "b-input-group-prepend",
                                              [
                                                _c(
                                                  "b-button",
                                                  {
                                                    attrs: {
                                                      id: "QUOTA_SUBTRACT",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.preventDefault()
                                                        return _vm.quotaAddMinus(
                                                          -1
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("font-awesome-icon", {
                                                      attrs: {
                                                        icon: ["far", "minus"],
                                                      },
                                                    }),
                                                    _c("b-popover", {
                                                      attrs: {
                                                        target:
                                                          "QUOTA_SUBTRACT",
                                                        placement: "top",
                                                        triggers: "hover",
                                                        content: _vm.$t(
                                                          "resource.button.quota_subtract"
                                                        ),
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c("b-form-input", {
                                          staticClass: "form-label",
                                          attrs: {
                                            type: "text",
                                            readonly:
                                              _vm.isResourceQuotaReadOnly,
                                          },
                                          on: { blur: _vm.onQuotaBlur },
                                          model: {
                                            value: _vm.resource.resourceQuota,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.resource,
                                                "resourceQuota",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "resource.resourceQuota",
                                          },
                                        }),
                                        !_vm.isResourceQuotaReadOnly
                                          ? _c(
                                              "b-input-group-append",
                                              [
                                                _c(
                                                  "b-button",
                                                  {
                                                    attrs: { id: "QUOTA_ADD" },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.preventDefault()
                                                        return _vm.quotaAddMinus(
                                                          1
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("font-awesome-icon", {
                                                      attrs: {
                                                        icon: ["far", "plus"],
                                                      },
                                                    }),
                                                    _c("b-popover", {
                                                      attrs: {
                                                        target: "QUOTA_ADD",
                                                        placement: "top",
                                                        triggers: "hover",
                                                        content: _vm.$t(
                                                          "resource.button.quota_add"
                                                        ),
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-form-invalid-feedback",
                                      {
                                        staticClass:
                                          "alert-danger form-field-alert",
                                        class: {
                                          "d-block":
                                            _vm.showResourceResourceQuotaError,
                                        },
                                      },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: {
                                            icon: ["far", "circle-exclamation"],
                                          },
                                        }),
                                        _vm._v(
                                          "  " +
                                            _vm._s(
                                              _vm.errors.first(
                                                "resource.resourceQuota"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["resourceQuota"] != null
                          ? _vm._l(
                              _vm.customFieldMap["resourceQuota"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "resourceQuota" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.resource[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.resource,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "resource[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isPayFrequencyVisible
                          ? _c(
                              "b-col",
                              { staticClass: "pr-0", attrs: { cols: "12" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.$t("resource.field.frequency"),
                                      "label-for": "frequency",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-select", {
                                          directives: [
                                            {
                                              name: "validate",
                                              rawName: "v-validate",
                                              value: { required: true },
                                              expression: "{ required: true }",
                                            },
                                          ],
                                          attrs: {
                                            options: _vm.payFrequencyOptions,
                                            "data-vv-as": _vm.$t(
                                              "resource.field.frequency"
                                            ),
                                            "data-vv-name":
                                              "resource.payFrequency",
                                            "data-vv-delay": "500",
                                            disabled:
                                              _vm.isPayFrequencyReadOnly,
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "first",
                                                fn: function () {
                                                  return [
                                                    _c(
                                                      "b-form-select-option",
                                                      {
                                                        attrs: {
                                                          value: null,
                                                          disabled: "",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "resource.select_frequency"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            false,
                                            4236985587
                                          ),
                                          model: {
                                            value: _vm.resource.payFrequency,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.resource,
                                                "payFrequency",
                                                $$v
                                              )
                                            },
                                            expression: "resource.payFrequency",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-form-invalid-feedback",
                                      {
                                        staticClass:
                                          "alert-danger form-field-alert",
                                        class: {
                                          "d-block": _vm.showFrequencyError,
                                        },
                                      },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: {
                                            icon: ["far", "circle-exclamation"],
                                          },
                                        }),
                                        _vm._v(
                                          "  " +
                                            _vm._s(
                                              _vm.errors.first(
                                                "resource.payFrequency"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["payFrequency"] != null
                          ? _vm._l(
                              _vm.customFieldMap["payFrequency"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "payFrequency" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.resource[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.resource,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "resource[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isTagVisible
                          ? _c(
                              "b-col",
                              { staticClass: "pr-0", attrs: { cols: "12" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c("TagList", {
                                      attrs: {
                                        holderId: _vm.id,
                                        tags: _vm.tags,
                                        readOnly: _vm.isTagReadOnly,
                                      },
                                      on: { modified: _vm.tagsModified },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["tags"] != null
                          ? _vm._l(
                              _vm.customFieldMap["tags"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "tags" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.resource[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.resource,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "resource[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isStorageFileVisible
                          ? _c(
                              "b-col",
                              { staticClass: "pr-0", attrs: { cols: "12" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c("label", { staticClass: "mr-1" }, [
                                      _vm._v(
                                        _vm._s(_vm.$t("project.field.files"))
                                      ),
                                    ]),
                                    !_vm.isStorageFileReadOnly
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "btn-action",
                                            attrs: { id: "FILE_ADD" },
                                            on: {
                                              click: _vm.fileSelectorToggle,
                                            },
                                          },
                                          [
                                            _c("font-awesome-icon", {
                                              attrs: { icon: ["far", "plus"] },
                                            }),
                                            _c("b-popover", {
                                              attrs: {
                                                target: "FILE_ADD",
                                                placement: "top",
                                                triggers: "hover",
                                                content: _vm.$t(
                                                  "project.button.file_add"
                                                ),
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c("BadgeGroup", {
                                      attrs: {
                                        readOnly: _vm.isStorageFileReadOnly,
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var item = ref.item
                                              var index = ref.index
                                              return [
                                                _c("Badge", {
                                                  key: index,
                                                  attrs: {
                                                    text: _vm.labelFilename(
                                                      item.name,
                                                      item.type
                                                    ),
                                                    variant: "primary",
                                                    pillable: !!item.pillable,
                                                    readOnly:
                                                      _vm.isStorageFileReadOnly,
                                                    enableClickWhenReadOnly: "",
                                                  },
                                                  on: {
                                                    badgeRemove: function (
                                                      $event
                                                    ) {
                                                      return _vm.fileBadgeRemove(
                                                        index
                                                      )
                                                    },
                                                    badgeClick: function (
                                                      $event
                                                    ) {
                                                      return _vm.fileBadgeClick(
                                                        item
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1579555038
                                      ),
                                      model: {
                                        value: _vm.files,
                                        callback: function ($$v) {
                                          _vm.files = $$v
                                        },
                                        expression: "files",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["files"] != null
                          ? _vm._l(
                              _vm.customFieldMap["files"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "files" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.resource[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.resource,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "resource[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isNoteVisible
                          ? _c(
                              "b-col",
                              { staticClass: "pr-0", attrs: { cols: "12" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c("NoteList", {
                                      attrs: {
                                        readOnly: _vm.isNoteReadOnly,
                                        notes: _vm.notes,
                                      },
                                      on: {
                                        add: _vm.addNote,
                                        edit: _vm.editNote,
                                        toRemove: _vm.removeNote,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["notes"] != null
                          ? _vm._l(
                              _vm.customFieldMap["notes"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "notes" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.resource[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.resource,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "resource[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ],
        ],
        2
      ),
      _vm.state.fileSelectorShow
        ? _c("FileSelectorModal", {
            attrs: { show: _vm.state.fileSelectorShow },
            on: {
              "update:show": function ($event) {
                return _vm.$set(_vm.state, "fileSelectorShow", $event)
              },
              ok: _vm.fileSelectorOk,
            },
          })
        : _vm._e(),
      _vm.exists
        ? [
            _vm.state.historyShow
              ? _c("GenericHistoryModal", {
                  attrs: {
                    show: _vm.state.historyShow,
                    id: _vm.id,
                    entityType: "RESOURCE",
                    customFields: _vm.customFields,
                    links: "NOTE,STORAGE_FILE,TAG",
                  },
                  on: {
                    "update:show": function ($event) {
                      return _vm.$set(_vm.state, "historyShow", $event)
                    },
                  },
                })
              : _vm._e(),
            _vm.state.noteShow
              ? _c("NoteModal", {
                  attrs: { show: _vm.state.noteShow, note: _vm.note },
                  on: {
                    "update:show": function ($event) {
                      return _vm.$set(_vm.state, "noteShow", $event)
                    },
                    toAdd: _vm.toAddNote,
                    toUpdate: _vm.toUpdateNote,
                  },
                })
              : _vm._e(),
            _vm.showStaffSelector
              ? _c("StaffSelectorModal", {
                  attrs: {
                    mode: "MANAGE",
                    resource: _vm.resource,
                    show: _vm.showStaffSelector,
                  },
                  on: {
                    "update:show": function ($event) {
                      _vm.showStaffSelector = $event
                    },
                  },
                })
              : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }